export const ADMIN_LOGIN = "/auth/admin_login";
export const ADMIN_PROFILE = "/admin/profile";

export const ADMIN_ENDPOINT = "/admin";
export const ASSIGN_ROLE_ENDPOINT = "/admin/assign_role";
export const MODULE_ENDPOINT = "/module";
export const ROLE_ENDPOINT = "/role";
export const ACTIVITY_LOG_ENDPOINT = "/activity_log";

export const FILE_ENDPOINT = "/files";

export const CAMPAIGN_ENDPOINT = "/campaign";
export const CAMPAIGN_ENTRY_ENDPOINT = "/campaign_entry";

export const MISSION_ENDPOINT = "/mission";
export const MISSION_ENTRY_ENDPOINT = "/mission_entry";

export const USER_ENDPOINT = "/user";

export const BANNER_ENDPOINT = "/banner";

export const INFLUENCER_ENDPOINT = "/influencer";
export const CATEGORY_ENDPOINT = "/category";

export const BRAND_ENDPOINT = "/brand";
export const SOCIAL_POST_ENDPOINT = "/social_post";
